import React from "react";
import slide1 from "../assets/TricornerFront2.png";

export default function Hero() {
  return (
    <section>
      <div className="container">
        <div
          className="hero h-96 md:h-[500px] rounded-box overflow-hidden"
          // style={{
          //   backgroundImage: `url("https://placeimg.com/1000/800/tech")`,
          // }}
        >
          <img
            src={slide1}
            className="w-full h-auto hero-content rounded-box"
            alt="Tricorner X Delivery Services"
          />
          {/* <div className="hero-overlay bg-opacity-60 base-content"></div> */}
          {/* <div className="hero-content flex-col lg:flex-row">
            <div className="flex-none">
              <h1 className="mb-5 sm:mb-7 text-4xl sm:text-5xl font-bold">
                Tracking
              </h1>
              <p className="mb-5 sm:mb-7 sm:text-lg">
                Track your shipments with ease.
              </p>
             
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
